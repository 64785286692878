import Card from '@/components/global/Card';

import DynamicComponent from './DynamicComponent';

const CardWrapper = ({ blok }: any) => {
  const {
    variant,
    layout,
    fullHeight,
    children,
    tags,
    link,
    growChild,
    cardHoverEffect,
    cssVars,
    modal,
    disableBackground,
    imageAsBackground
  } = blok;

  return (
    <Card
      variant={variant}
      layout={layout}
      growChild={growChild}
      link={link && link?.cached_url}
      tags={
        tags &&
        tags?.length > 0 &&
        tags.map((bl: any) => <DynamicComponent blok={bl} key={bl._uid} />)
      }
      cardHoverEffect={cardHoverEffect}
      fullHeight={fullHeight}
      cssVars={cssVars}
      modal={
        (modal && modal?.length) > 0
          ? {
              modalTitle: modal[0].ModalTitle,
              modalContent: modal[0].ModalContent.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} />
              )),
              modalButtonLabel: modal[0].ModalButtonLabel
            }
          : undefined
      }
      disableBackground={disableBackground}
      imageAsBackground={imageAsBackground}
    >
      {children.map((bl: any) => (
        <DynamicComponent blok={bl} key={bl._uid} />
      ))}
    </Card>
  );
};

export default CardWrapper;
